<template>
  <v-card outlined v-if="selectedObject._country.length">
    <v-card-title>
      <span class="subtitle-2 font-weight-light text--secondary pa-0 ma-0">COUNTRIES</span>
    </v-card-title>
    <v-autocomplete
      class="pa-0 ma-0"
      :items="selectedObject._country"
      :value="selectedObject._country"
      flat
      solo
      readonly
      multiple
      append-icon=""
    >
      <template v-slot:selection="data">
        <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
          {{ data.item }}
        </v-chip>
      </template>
    </v-autocomplete>
  </v-card>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'locations',
  data: function () {
    return {};
  },
  props: {
    selectedObject: Object,
  },
  computed: {},
  methods: {},
  async mounted() {},
};
</script>
