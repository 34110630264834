<template>
  <v-card outlined>
    <v-card-title>
      <span class="subtitle-1 font-weight-light text--secondary pa-0 ma-0">PAYOUT TYPE</span>
    </v-card-title>
    <v-text-field
      class="pa-0 ma-0"
      v-model="types[selectedObject.type]"
      solo
      flat
      readonly
    ></v-text-field>
    <v-simple-table v-if="selectedObject.type === 'PER_ACTION_AND_PER_SALE'" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">VALUE</th>
            <th class="text-left">PERCENTAGE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selectedObject.costs" :key="index">
            <td v-html="`${currencySymbol}${item.value}`">{{}}</td>
            <td>{{ item.percentage }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table v-else-if="selectedObject.type === 'PER_SALE'" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">PERCENTAGE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selectedObject.costs" :key="index">
            <td>{{ item.percentage }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table v-else dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">VALUE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selectedObject.costs" :key="index">
            <td v-html="`${currencySymbol}${item.value}`">{{}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import _ from 'lodash';
import currency from './currency.js';

export default {
  name: 'payment-type-component',
  data: function () {
    return {
      types: {
        PER_CLICK: 'CPC',
        PER_SALE: 'CPS',
        PER_ACTION: 'CPA',
        PER_ACTION_AND_PER_SALE: 'CPA + CPS',
      },
    };
  },
  props: {
    selectedObject: Object,
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    currencyId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currencySymbol() {
      let index = currency.list.findIndex((element) => element._id === this.currencyId);
      return currency.list[index].symbol;
    },
  },
};
</script>
