<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card color="transparent ma-0 pa-0" elevation="0">
        <v-card-actions class="ma-0 pa-0">
          <v-spacer />
          <v-btn @click="unselectItem()" class="ma-1" depressed small>
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('commons.operation.back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card class="mx-auto mt-5 elevation-0" outlined>
        <v-row justify="space-between">
          <v-col cols="12" sm="3" md="3" xl="3">
            <v-card class="v-sheet--offset ml-7" elevation="0" max-width="266px" outlined>
              <transition name="scale-transition">
                <v-img
                  v-if="Offers.state.item.thumbnail"
                  :lazy-src="Offers.state.item.thumbnail"
                  :src="Offers.state.item.thumbnail"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-skeleton-loader
                  v-else
                  class="mx-auto"
                  height="320"
                  type="image"
                ></v-skeleton-loader>
              </transition>
            </v-card>
            <div class="ml-7" align="center" justify="center">
              <v-chip
                class="mt-8"
                :href="Offers.state.item.previewURL"
                target="blank"
                label
                dense
                style="margin-left: -28px"
              >
                <v-icon>mdi-eye-outline</v-icon>
                <span class="ml-3">Landing Page</span>
              </v-chip>
            </div>
            <div
              class="ml-7"
              align="center"
              justify="center"
              v-for="(url, index) in Offers.state.item.alternativePreviewURLs"
              :key="url"
              hidden
            >
              <v-chip
                class="mt-1"
                align="center"
                justify="center"
                target="blank"
                :href="url"
                label
                dense
              >
                <v-icon>mdi-eye-outline</v-icon>
                <span class="ml-3">Alternative LP {{ index + 2 }}</span>
              </v-chip>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="6" xl="6">
            <v-card class="mx-auto elevation-0" color="transparent" outlined>
              <v-card-title class="title font-weight-light mt-3 mb-0 pt-0">
                {{ Offers.state.item.name }}
              </v-card-title>
              <v-card-text>
                {{ Offers.state.item.description }}
              </v-card-text>
              <v-card-text
                v-if="Offers.state.application"
                class="title font-weight-light mt-3 mb-0 pt-0"
              >
                <v-spacer />
                <v-dialog v-model="TAC_Dialog" max-width="800px">
                  <v-card>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      {{ $t('commons.titles.TermsConditions') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <div v-html="Offers.state.item.termsAndConditions" />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="TAC_Dialog = false">
                        Disagree
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="Offers.actions.runApplication({}) && (TAC_Dialog = false)"
                      >
                        Agree
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="SURVEY_Dialog" max-width="800px">
                  <v-card>
                    <v-card-title
                      class="text-capitalize font-weight-light"
                      v-if="Offers.state.application.survey"
                    >
                      <v-spacer />
                      {{ $t('commons.titles.requestApproval') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" v-if="Offers.state.application.survey" />
                    <v-card-text v-if="Offers.state.application.survey">
                      <v-form v-model="valid">
                        <v-container>
                          <v-layout wrap>
                            <v-flex
                              xs12
                              v-for="(question, index) in Offers.state.application.survey"
                              :key="index"
                            >
                              <v-card class="elevation-0 mb-1">
                                <v-card-title class="text-md-left subheading font-weight-regular">
                                  {{ question.title }}
                                </v-card-title>
                                <v-card-text class="text-md-left">
                                  <v-text-field
                                    v-if="question.type === 'Text'"
                                    :name="question.flag"
                                    v-model="question.value"
                                    clearable
                                    solo-inverted
                                    flat
                                    required
                                    :rules="[(v) => !!v || 'The answer is required']"
                                  />
                                  <v-textarea
                                    v-else-if="question.type === 'Textarea'"
                                    :name="question.flag"
                                    v-model="question.value"
                                    auto-grow
                                    solo-inverted
                                    flat
                                    required
                                    :rules="[(v) => !!v || 'The answer is required']"
                                  />
                                  <v-select
                                    v-else-if="question.type === 'Select'"
                                    :label="question.title"
                                    v-model="question.value"
                                    :items="question.items"
                                    solo-inverted
                                    flat
                                    required
                                    :rules="[(v) => !!v || 'The answer is required']"
                                  />
                                </v-card-text>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      {{ $t('commons.titles.TermsConditions') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <div v-html="Offers.state.item.termsAndConditions" />
                    </v-card-text>
                    <v-divider class="mx-10" />
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="SURVEY_Dialog = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        :disabled="!valid"
                        color="green darken-1"
                        text
                        @click="
                          Offers.actions.runApplication({
                            survey: Offers.state.application.survey,
                            type: Offers.state.application.type,
                            status: 'Apply to Run',
                          }) && (SURVEY_Dialog = false)
                        "
                      >
                        Agree & Request approval
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  v-if="
                    Offers.state.application.status && Offers.state.application.type == 'approved'
                  "
                  @click.stop="
                    Offers.state.application.forceTermsAndConditions
                      ? (TAC_Dialog = true)
                      : Offers.actions.runApplication({ status: 'Approved' })
                  "
                  depressed
                  label
                  class="font-weight-regular"
                  color="green"
                  dark
                  block
                  >Get tracking link</v-btn
                >
                <v-btn
                  v-else-if="
                    Offers.state.application.status && Offers.state.application.type == 'unapproved'
                  "
                  @click.stop="SURVEY_Dialog = true"
                  depressed
                  label
                  class="font-weight-regular"
                  color="blue lighten-1"
                  dark
                  block
                  >Apply to run</v-btn
                >
                <v-btn
                  v-else-if="'pending' == Offers.state.application._id"
                  depressed
                  label
                  class="font-weight-regular"
                  color="red lighten-1"
                  dark
                  block
                  >Pending approval status</v-btn
                >
                <v-btn
                  v-else-if="'rejected' == Offers.state.application._id"
                  depressed
                  label
                  class="font-weight-regular"
                  color="red lighten-1"
                  dark
                  block
                  >Your application to the offer has been rejected</v-btn
                >
                <v-window v-else-if="Offers.state.application._id" show-arrows class="mx-auto">
                  <v-window-item>
                    <v-card color="transparent" elevation="0" height="200">
                      <v-row class="fill-height" align="center" justify="center">
                        <v-card-title class="ma-0 pa-0"> Default tracking link </v-card-title>
                        <v-card-text class="text-center m-1 p-1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-on="on"
                                style="max-width: 600px"
                                depressed
                                label
                                class="font-weight-regular scrollable-chip"
                                color="blue lighten-1"
                                dark
                                @click="
                                  copyToClipboard(
                                    `https://affcpatrk.com/track?id=${Offers.state.application._id}${affSubsParams}`
                                  )
                                "
                              >
                                <span>Copy</span>
                                <v-icon class="mx-3">mdi-clipboard</v-icon>
                                {{
                                  `https://affcpatrk.com/track?id=${Offers.state.application._id}${affSubsParams}`
                                }}
                              </v-chip>
                            </template>
                            <span>{{
                              `https://affcpatrk.com/track?id=${Offers.state.application._id}${affSubsParams}`
                            }}</span>
                          </v-tooltip>
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </v-window-item>
                  <v-window-item
                    v-for="(_, index) in Offers.state.item.alternativeUrls"
                    :key="index"
                  >
                    <v-card color="transparent" height="200">
                      <v-row class="fill-height" align="center" justify="center">
                        <v-card-title class="ma-0 pa-0">
                          Tracking link LP {{ index + 2 }}
                        </v-card-title>
                        <v-card-text class="text-center">
                          <v-chip
                            depressed
                            label
                            class="font-weight-regular"
                            color="blue lighten-1"
                            dark
                            @click="
                              copyToClipboard(
                                `https://affcpatrk.com/track?id=${
                                  Offers.state.application._id
                                }&lp=${index + 1}`
                              )
                            "
                          >
                            <span>Copy</span>
                            <v-icon class="mx-3">mdi-clipboard</v-icon>
                            {{
                              `https://affcpatrk.com/track?id=${Offers.state.application._id}&lp=${
                                index + 1
                              }`
                            }}
                          </v-chip>
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </v-window-item>
                  <v-window-item v-if="Offers.state.item.alternativeUrls.length > 0">
                    <v-card color="transparent" height="200">
                      <v-row class="fill-height" align="center" justify="center">
                        <v-card-title class="ma-0 pa-0">Rotative tracking link</v-card-title>
                        <v-card-text class="text-center">
                          <v-chip
                            depressed
                            label
                            class="font-weight-regular"
                            color="blue lighten-1"
                            dark
                            @click="
                              copyToClipboard(
                                `https://affcpatrk.com/track?id=${Offers.state.application._id}&lp=rotate`
                              )
                            "
                          >
                            <span>Copy</span>
                            <v-icon class="mx-3">mdi-clipboard</v-icon>
                            {{
                              `https://affcpatrk.com/track?id=${Offers.state.application._id}&lp=rotate`
                            }}
                          </v-chip>
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </v-window-item>
                </v-window>
                <v-spacer />
              </v-card-text>
              <v-progress-linear v-else indeterminate height="33" />
              <v-row>
                <v-col>
                  <v-card-subtitle class="mt-0">
                    <v-chip-group :show-arrows="false">
                      <div class="status-container">
                        <span class="subheading font-weight-light">Status:&nbsp;</span>
                        <v-chip
                          small
                          label
                          :style="colorStatus(Offers.state.item.status)"
                          class="font-weight-bold"
                          >{{ Offers.state.item.status }}</v-chip
                        >
                      </div>
                    </v-chip-group>

                    <v-chip-group :show-arrows="false">
                      <span class="subheading font-weight-light">Expiration date:&nbsp;</span>

                      {{ Offers.state.item.expiresOn | date }}
                    </v-chip-group>
                    <span class="subheading font-weight-light">Payout</span>
                    <v-chip-group
                      :show-arrows="false"
                      v-if="Offers.state.item.payout.model !== 'BY_GROUP'"
                    >
                      <v-chip class="white--text" color="blue" label>
                        {{ Offers.state.item.payout | payoutField(Offers.state.item.currency) }}
                      </v-chip>
                    </v-chip-group>
                    <v-chip-group :show-arrows="false" v-else>
                      <v-btn
                        @click="openMultiplePayoutPanels"
                        class="white--text"
                        color="blue"
                        label
                      >
                        Show Multiple Payouts
                      </v-btn>
                    </v-chip-group>
                    <span
                      class="subheading font-weight-light"
                      v-if="Offers.state.item.events.length"
                      >Events</span
                    >
                    <v-chip-group
                      v-for="(event, index) in Offers.state.item.events"
                      :key="index"
                      :show-arrows="false"
                    >
                      <v-chip>{{ event.name }} = {{ event.payout.costs[0].value }}</v-chip>
                    </v-chip-group>
                  </v-card-subtitle>
                </v-col>
                <v-col
                  v-if="
                    Offers.state.application &&
                    Offers.state.application.applicationStatus == 'running'
                  "
                >
                  <AffSub @input="affSubsParams = $event" />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" xl="3" align="right">
            <v-chip depressed label class="font-weight-regular mt-4 mr-4" color="primary">
              Offer ID: {{ Offers.state.item._id }}
            </v-chip>
          </v-col>
        </v-row>
        <v-card-text>
          <v-expansion-panels v-model="panel" popout outlined>
            <v-expansion-panel v-if="Offers.state.item.payout.model == 'BY_GROUP'">
              <v-expansion-panel-header
                class="text-capitalize text-left font-weight-light"
                expand-icon="mdi-cursor-pointer"
                >Multiple Payouts</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card flat tile>
                  <v-data-table
                    v-bind:headers="headers2"
                    v-bind:items="multiplePayouts"
                    v-bind:no-data-text="$t('commons.table.noDataText')"
                    v-bind:no-results-text="$t('commons.table.noResultsText')"
                    v-bind:hide-default-footer="true"
                    disable-pagination
                  >
                    <template v-slot:item._country="{ item }">
                      <span v-for="(country, index) in item._country" :key="index">
                        <span v-if="index > 0">, </span>
                        <span :title="Countries[country]">{{ country }}</span>
                      </span>
                    </template>
                    <template v-slot:item.deviceType="{ item }">
                      {{ item.deviceType.join(', ') }}
                    </template>
                    <template v-slot:item.deviceOs="{ item }">
                      {{ item.deviceOs.join(', ') }}
                    </template>
                    <template v-slot:item.costValue="{ item }">
                      {{ currencySymbol + item.costValue }}
                    </template>
                  </v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="text-capitalize text-left font-weight-light"
                expand-icon="mdi-cursor-pointer"
                >Terms & Conditions</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card flat tile>
                  <v-card-title class="text-capitalize font-weight-light">
                    <v-spacer />
                    {{ $t('commons.titles.TermsConditions') }}
                    <v-spacer />
                  </v-card-title>
                  <v-divider class="mx-10" />
                  <v-card-text v-html="Offers.state.item.termsAndConditions" />
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="text-capitalize text-left font-weight-light"
                expand-icon="mdi-cursor-pointer"
                >Targeting & Capping</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card flat tile>
                  <v-card-title class="text-capitalize font-weight-light">
                    <v-spacer />
                    {{ $t('commons.titles.targetingCapping') }}
                    <v-spacer />
                  </v-card-title>
                  <v-divider class="mx-10" />
                  <v-card-text>
                    <TargetingCapping :offer="Offers.state.item" />
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="text-capitalize text-left font-weight-light"
                expand-icon="mdi-cursor-pointer"
                >Third Party Tracking</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card flat tile>
                  <v-divider class="mx-10" />
                  <v-card-text>
                    <AffiliatePixels
                      :offer="Offers.state.item"
                      :viewFilters="false"
                      :viewTitle="false"
                      :viewEdit="false"
                    />
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="text-capitalize text-left font-weight-light"
                expand-icon="mdi-cursor-pointer"
                >Creatives</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card flat tile>
                  <v-card-title class="text-capitalize font-weight-light">
                    <v-spacer />
                    {{ $t('commons.titles.creatives') }}
                    <v-spacer />
                  </v-card-title>
                  <v-divider class="mx-10" />
                  <creatives :_offer="Offers.state.item._id" />
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="ma-0 pt-0">
          <v-spacer />
          <v-btn @click="unselectItem()" class="ma-1" depressed small>
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('commons.operation.back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { inject, watch, computed, reactive } from '@vue/composition-api';
import TargetingCapping from './TargetingCapping.vue';
import Creatives from './Creatives';
import AffiliatePixels from '../AffiliatePixels';
import numeral from 'numeral';
import Locations from './Locations.vue';
import DeviceComponent from './DeviceComponent.vue';
import PaymentTypeComponent from './PaymentTypeComponent.vue';
import Countries from '../../../store/constants/Countries';
import AffSub from '../../Components/AffSub.vue';

export default {
  name: 'offer-preview',
  components: {
    TargetingCapping,
    creatives: Creatives,
    AffiliatePixels,
    Locations,
    DeviceComponent,
    PaymentTypeComponent,
    AffSub,
  },
  data() {
    return {
      panel: null,
    };
  },
  methods: {
    colorStatus(status) {
      let colorStat = status === 'ACTIVE' ? 'backgroundColor:#c0f3b6; color:#009600;' : '';
      return colorStat;
    },

    openMultiplePayoutPanels() {
      this.panel = 0;
    },
  },
  setup(props, context) {
    const Offers = inject('Offers');
    const unselectItem = () => {
      Offers.actions.unselectItem();
      context.emit('setPreSelectItemPage');
    };
    const self = reactive({
      search: '',
      valid: false,
      TAC_Dialog: false,
      SURVEY_Dialog: false,
      innerStatistics: computed(() => Offers.state.item.statistics),
      CcrChart: computed(() =>
        (self.innerStatistics && self.innerStatistics.CCRLast7days
          ? self.innerStatistics.CCRLast7days
          : []
        ).reduce(
          (accumulator, currentValue) => {
            accumulator.options.xaxis.categories.push(
              `${currentValue['month']}/${currentValue['day']}`
            );
            accumulator.series[0].data.push(
              currentValue['transactions'] && _.isNumber(currentValue['transactions'])
                ? currentValue['transactions']
                : 0
            );
            accumulator.series[1].data.push(
              currentValue['conversions'] && _.isNumber(currentValue['conversions'])
                ? currentValue['conversions']
                : 0
            );
            accumulator.series[2].data.push(
              currentValue[`payout${Offers.state.currency}`] &&
                _.isNumber(currentValue[`payout${Offers.state.currency}`])
                ? _.round(currentValue[`payout${Offers.state.currency}`], 3)
                : 0
            );
            return accumulator;
          },
          {
            series: [
              {
                name: 'Clicks',
                data: [],
                color: '#FF0000',
              },
              {
                name: 'Conversions',
                data: [],
                color: '#FFCC00',
              },
              {
                name: 'Payout',
                data: [],
                color: '#0088EE',
              },
            ],
            options: {
              chart: {
                height: 380,
                type: 'line',
                zoom: {
                  enabled: false,
                },
                dropShadow: {
                  enabled: false,
                  color: '#000',
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2,
                },
                toolbar: {
                  show: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'straight',
                width: [3, 3, 3],
                dashArray: [0, 0, 0],
              },
              legend: {
                tooltipHoverFormatter: function (val, opts) {
                  if (val == 'Clicks' || val == 'Conversions') {
                    return (
                      val +
                      ' - ' +
                      numeral(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]).format(
                        '0,0'
                      ) +
                      ''
                    );
                  } else {
                    return (
                      val +
                      ' - ' +
                      numeral(opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]).format(
                        '0,0.00'
                      ) +
                      ''
                    );
                  }
                },
              },
              markers: {
                size: 0,
                hover: {
                  sizeOffset: 10,
                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: [],
              },
              yaxis: [
                {
                  seriesName: 'Clicks',
                  opposite: false,
                  axisTicks: {
                    show: true,
                    color: '#FF0000',
                  },
                  axisBorder: {
                    show: true,
                    color: '#FF0000',
                  },
                  labels: {
                    show: window.innerWidth >= 1264,
                    style: {
                      colors: '#FF0000',
                    },
                    formatter: function (val) {
                      return numeral(val).format('0,0');
                    },
                  },
                },
                {
                  seriesName: 'Conversions',
                  opposite: false,
                  axisTicks: {
                    show: true,
                    color: '#FFCC00',
                  },
                  axisBorder: {
                    show: true,
                    color: '#FFCC00',
                  },
                  labels: {
                    show: window.innerWidth >= 1264,
                    style: {
                      colors: '#FFCC00',
                    },
                    formatter: function (val) {
                      return numeral(val).format('0,0');
                    },
                  },
                },
                {
                  seriesName: 'Payout',
                  opposite: true,
                  axisTicks: {
                    show: true,
                    color: '#0088EE',
                  },
                  axisBorder: {
                    show: true,
                    color: '#0088EE',
                  },
                  labels: {
                    show: window.innerWidth >= 1264,
                    style: {
                      colors: '#0088EE',
                    },
                    formatter: function (val) {
                      return Offers.state.symbol + ' ' + numeral(val).format('0,0.00');
                    },
                  },
                },
              ],
              tooltip: {
                color: '#42A5F5',
              },
              grid: {
                borderColor: '#f1f1f100',
              },
            },
          }
        )
      ),
      multiplePayouts: computed(() => {
        const payouts = [];
        if (Offers.state.item.payout.costs[0].value > 0) {
          payouts.push({
            _country: ['Default'],
            deviceType: Offers.state.item.deviceType,
            deviceOs: Offers.state.item.deviceOs,
            costs: Offers.state.item.payout.costs,
          });
        }
        payouts.push.apply(payouts, Offers.state.item.payout.group);
        payouts.forEach((e) => {
          e._country = e._country.length > 0 ? e._country : ['All'];
          e.deviceType = e.deviceType.length > 0 ? e.deviceType : ['All'];
          e.deviceOs = e.deviceOs.length > 0 ? e.deviceOs : ['All'];
          e.costValue = e.costs[0].value;
        });
        return payouts;
      }),
      currencySymbol: computed(() => {
        return Offers.state.item.currency == 'USD' ? '$' : '€';
      }),
      affSubsParams: '',
    });
    const headers = [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        width: 100,
      },
      { text: 'Creative', value: 'name' },
      { text: 'Type', value: 'type', align: 'start', width: 200 },
      { text: 'Action', value: 'link', align: 'start', width: 100, filterable: false },
    ];
    const headers2 = [
      {
        text: 'Country',
        align: 'start',
        value: '_country',
        width: 200,
      },
      {
        text: 'Device',
        align: 'start',
        value: 'deviceType',
        width: 300,
      },
      {
        text: 'OS',
        align: 'start',
        value: 'deviceOs',
        width: 300,
      },
      {
        text: 'Payout',
        align: 'start',
        value: 'costValue',
        width: 100,
      },
    ];
    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };
    return { Offers, ...self, headers, headers2, copyToClipboard, unselectItem, Countries };
  },
};
</script>
<style>
.scrollable-chip {
  overflow-x: auto;
}
.scrollable-chip::-webkit-scrollbar {
  height: 5px;
}
.scrollable-chip::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
.status-container {
  display: flex;
  align-items: center; /* Vertically center the content */
}
</style>
