<template>
  <v-card outlined>
    <template v-if="selectedObject.deviceType.length">
      <v-card-title>
        <span class="subtitle-2 font-weight-light text--secondary pa-0 ma-0">DEVICE TYPE</span>
      </v-card-title>
      <v-autocomplete
        class="pa-0 ma-0"
        :items="selectedObject.deviceType"
        :value="selectedObject.deviceType"
        readonly
        multiple
        append-icon=""
        flat
        solo
        single-line
        hide-details
      >
        <template v-slot:selection="data">
          <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
            {{ data.item }}
          </v-chip>
        </template>
      </v-autocomplete>
    </template>
    <template v-if="selectedObject.deviceOs.length">
      <v-card-title>
        <span class="subtitle-2 font-weight-light text--secondary pa-0 ma-0">DEVICE OS</span>
      </v-card-title>
      <v-autocomplete
        class="pa-0 ma-0"
        :items="selectedObject.deviceOs"
        :value="selectedObject.deviceOs"
        readonly
        multiple
        append-icon=""
        flat
        solo
        single-line
        hide-details
      >
        <template v-slot:selection="data">
          <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
            {{ data.item }}
          </v-chip>
        </template>
      </v-autocomplete>
    </template>
    <template v-if="selectedObject.deviceOs.length">
      <v-row>
        <v-col v-if="selectedObject.deviceOsVersionMinimum">
          <v-card-title>
            <span class="subtitle-2 font-weight-light text--secondary pa-0 ma-0"
              >DEVICE MODEL VERSION MIN</span
            >
          </v-card-title>
          <v-text-field
            :value="selectedObject.deviceOsVersionMinimum"
            readonly
            multiple
            flat
            solo
            single-line
            hide-details
          />
        </v-col>
        <v-col v-if="selectedObject.deviceOsVersionMaximum">
          <v-card-title>
            <span class="subtitle-2 font-weight-light text--secondary pa-0 ma-0"
              >DEVICE MODEL VERSION MAX</span
            >
          </v-card-title>
          <v-text-field
            :value="selectedObject.deviceOsVersionMaximum"
            readonly
            multiple
            flat
            solo
            single-line
            hide-details
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="selectedObject.deviceClient.length">
      <v-card-title>
        <span class="subtitle-2 font-weight-light text--secondary pa-0 ma-0">DEVICE CLIENT</span>
      </v-card-title>
      <v-autocomplete
        :value="selectedObject.deviceClient"
        :items="selectedObject.deviceClient"
        readonly
        multiple
        append-icon=""
        flat
        solo
        single-line
        hide-details
      >
        <template v-slot:selection="data">
          <v-chip small label v-bind="data.attrs" :input-value="data.selected" class="ma-1">
            {{ data.item }}
          </v-chip>
        </template>
      </v-autocomplete>
    </template>
  </v-card>
</template>
<script>
export default {
  name: 'device-component',
  data: function () {
    return {};
  },
  props: {
    selectedObject: Object,
  },
  methods: {},
  mounted() {},
};
</script>
